import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { FocusSkillStar } from 'components/focus-skill-star'
import { useStore } from 'store/context'

type RailsFocusSkillStarProps = {
  skillId: string
  userId: string
  source: string
  size: number
}

export const RailsFocusSkillStar: React.VFC<RailsFocusSkillStarProps> =
  observer((props) => {
    const { skillId, userId, source, size } = props

    const { currentUser, userSkills } = useStore()
    const userSkill = userSkills.forSkillAndUser(skillId, userId)

    if (!userSkill) return null

    const isCurrentUser = currentUser?.id === userId

    return (
      <FocusSkillStar
        editable={isCurrentUser}
        size={size}
        source={source}
        userSkill={userSkill}
      />
    )
  })
