import * as React from 'react'

type SensitiveContentProps = {
  children: React.ReactElement | React.ReactElement[]
}

export const SensitiveContent = (props: SensitiveContentProps) => {
  const { children } = props

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          'data-recording-sensitive': true,
        } as React.Attributes)
      })}
    </>
  )
}
