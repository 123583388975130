import { observer } from 'mobx-react-lite'
import { Tooltip } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { FocusSkillStarVm } from './focus-skill-star-vm'
import { Star } from '@phosphor-icons/react'
import { UserSkill } from 'store/modules/user_skills'

type FocusSkillStarProps = {
  className?: string
  editable?: boolean
  size?: number
  source: string
  userSkill?: UserSkill
}

export const FocusSkillStar: React.VFC<FocusSkillStarProps> = observer(
  (props) => {
    const { className, editable, size = 14, source, userSkill } = props

    const vm = React.useMemo(() => {
      return userSkill && new FocusSkillStarVm(userSkill)
    }, [userSkill])

    if (!vm) return null

    return (
      <Tooltip content={vm.tooltipContent} disabled={!editable}>
        <button
          className={cn(className, 'flex')}
          disabled={!editable || vm.isButtonDisabled}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            vm.onClick(source)
          }}
          type="button"
        >
          <Star
            aria-hidden
            className={vm.className}
            size={size}
            weight={vm.weight}
          />
        </button>
      </Tooltip>
    )
  }
)
