import { MAX_NO_OF_FOCUS_SKILLS } from './utils'
import { store } from 'store/index'
import { successToast } from '../../utils/success-toast'
import { UserSkill } from 'store/modules/user_skills'

export class FocusSkillStarVm {
  constructor(private userSkill: UserSkill) {}

  get className() {
    return this.selected ? 'text-theme-50' : 'text-gray-600'
  }

  get isButtonDisabled() {
    return !this.selected && this.atLimit
  }

  get tooltipContent() {
    if (this.selected) return 'Unstar skill'

    return this.atLimit
      ? 'You already have 3 Focus Skills selected'
      : 'Star skill'
  }

  get weight() {
    return this.selected ? 'fill' : 'bold'
  }

  private get atLimit() {
    return (
      store.userSkills.focusedForUser(this.userSkill.user.id).length >=
      MAX_NO_OF_FOCUS_SKILLS
    )
  }

  private get selected() {
    return this.userSkill.focused
  }

  private dispatchFocusSkillEvent(type: string, id: string) {
    const focusSkillEvent = new CustomEvent(`focusskill:${type}`, {
      detail: { id },
    })

    document.dispatchEvent(focusSkillEvent)
  }

  async onClick(source: string) {
    await this.userSkill.update(
      { focused: !this.userSkill.focused },
      { source }
    )

    const skill = this.userSkill.skill

    if (this.userSkill.focused) {
      this.dispatchFocusSkillEvent('added', skill.id)
      successToast(
        `${skill.name} was successfully added as one of your focus skills.`
      )
    } else {
      this.dispatchFocusSkillEvent('removed', skill.id)
      successToast(
        `${skill.name} was successfully removed from your focus skills.`
      )
    }
  }
}
