import { observer } from 'mobx-react-lite'
import { AvatarButton, Tooltip, useModalContext } from 'src/design-system'
import * as React from 'react'
import clip from 'text-clipper'
import cn from 'classnames'
import { Action } from 'store/modules/actions'
import { ACTION_COMPLETED_MODAL_ID } from 'components/action-completed-modal/utils'
import { ActionOverflow } from './action-overflow'
import { CheckIcon } from './check-icon'
import { CHECKIN_ACTIONS_SECTION_SOURCE } from 'components/checkin-actions-section/utils'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { CreateFormProps } from 'components/create-form'
import { formatDate } from '../../utils/date-helpers'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { LoadingContent } from 'components/loading-content'
import { SensitiveContent } from 'components/sensitive-content'
import { SkillTags } from 'components/skill-tags'
import { throwConfettiOnClick } from '../../utils/throw-confetti'
import { useStore } from 'store/context'
import styles from './actions-table.module.scss'
import { ArrowRight, Calendar } from '@phosphor-icons/react'

type ActionTableRowProps = {
  action: Action
  characterLimit?: number
  editable: boolean
  focusSkillIds: string[]
  skillsCharacterLimit?: number
  source: string
}

export const ActionTableRow = observer((props: ActionTableRowProps) => {
  const {
    action,
    characterLimit,
    editable,
    focusSkillIds,
    skillsCharacterLimit,
    source,
  } = props

  const { openModal } = useModalContext()
  const store = useStore()
  const [loading, setLoading] = React.useState(false)

  const onClickContent = React.useCallback(() => {
    openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
      resourceId: action.id,
      resourceType: 'Action',
      source,
      user: action.user,
      tabs: ['action'],
    })
  }, [action, openModal, source])

  const onClickComplete = React.useCallback(
    async (e) => {
      setLoading(true)
      await store.actions.update(action.id, { completed: true }, { source })
      await store.activities.fetchAllWithAllIncludes(20)

      setLoading(false)
      throwConfettiOnClick(e)

      if (source !== CHECKIN_ACTIONS_SECTION_SOURCE) {
        openModal(ACTION_COMPLETED_MODAL_ID, { action, source })
      }
    },
    [action, openModal, source, store]
  )

  if (!action) return null

  const allowEdit =
    editable && (action.user.isCurrentUser || action.creator.isCurrentUser)
  const completable = !action.completed && editable

  const content = characterLimit
    ? clip(action.content, characterLimit, { html: true, maxLines: 3 })
    : action.content

  return (
    <li className="action-row border-0 border-r-0 flex flex-col gap-1 py-3">
      <div className="flex gap-3 justify-between w-full items-start">
        <LoadingContent className="-mr-1 scale-75" loading={loading}>
          {completable && (
            <Tooltip
              disabled={!completable}
              content={
                allowEdit
                  ? 'Complete action'
                  : 'Only the creator of this Action can complete it'
              }
            >
              <button
                onClick={onClickComplete}
                disabled={!allowEdit}
                type="button"
              >
                <CheckIcon completed={false} />
              </button>
            </Tooltip>
          )}
          {action.completed && (
            <Tooltip content="Completed action">
              <CheckIcon completed className="cursor-default" />
            </Tooltip>
          )}
        </LoadingContent>
        <div className="flex flex-col gap-1.5 flex-grow-1">
          <SensitiveContent>
            <div
              className={cn(
                allowEdit && 'whitespace-normal cursor-pointer',
                !allowEdit && 'whitespace-normal cursor-default',
                action.completed && 'opacity-50'
              )}
              onClick={allowEdit ? onClickContent : () => {}}
            >
              <HtmlContent className={cn(styles.actionText)}>
                {content}
              </HtmlContent>
            </div>
          </SensitiveContent>
          <div className="flex gap-1 justify-between w-full"></div>
          <div className="flex flex-row flex-wrap gap-3">
            <div className="flex flex-row gap-1 text-xs text-gray-600 items-center">
              {action.creator !== action.user && (
                <>
                  <AvatarButton
                    alt={action.creator.fullName}
                    fallbackText={action.creator.initials}
                    isInactive={true}
                    size="xs"
                    src={action.creator.avatarUrl}
                    tooltipContent={`Created by ${action.creator.fullName}`}
                  />
                  <ArrowRight className="text-gray-500" size={12} />
                </>
              )}
              <AvatarButton
                alt={action.user.fullName}
                fallbackText={action.user.initials}
                isInactive={true}
                size="xs"
                src={action.user.avatarUrl}
                tooltipContent={action.user.fullName}
              />
            </div>
            <SkillTags
              characterLimit={skillsCharacterLimit}
              focusSkillIds={focusSkillIds}
              showLinks={editable}
              skills={action.skills}
            />
            {action.dueDate && (
              <span className="flex items-center gap-1">
                <Calendar className="text-gray-500" size={12} />
                <time
                  dateTime={action.formattedDueDate}
                  className={cn(
                    'text-xs',
                    action.completed && 'opacity-50',
                    action.overdue ? 'text-red-600' : 'text-gray-600'
                  )}
                >
                  {formatDate(action.dueDate)}
                  {action.overdue && <span className="sr-only">(overdue)</span>}
                </time>
              </span>
            )}
          </div>
        </div>
        {allowEdit && (
          <div className="ml-auto">
            <ActionOverflow action={action} source={source} />
          </div>
        )}
      </div>
    </li>
  )
})
