import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Action } from 'store/modules/actions'
import { ActionTableRow } from './action-table-row'

type ActionsTableProps = {
  actions: Action[]
  characterLimit?: number
  editable?: boolean
  focusSkillIds: string[]
  skillsCharacterLimit?: number
  source: string
}

export const ActionsTable = observer((props: ActionsTableProps) => {
  const {
    actions,
    characterLimit,
    editable = false,
    focusSkillIds,
    skillsCharacterLimit,
    source,
  } = props

  if (actions.length === 0) return null

  return (
    <ul className="divide-y divide-gray-100 divide-solid list-none p-0 flex flex-col">
      {actions.map((action) => (
        <ActionTableRow
          action={action}
          characterLimit={characterLimit}
          editable={editable}
          focusSkillIds={focusSkillIds}
          // Using the action's id as the key doesn't trigger a store update so workaround for now
          key={`${action.id}-${action.updatedAt.toISOString()}`}
          skillsCharacterLimit={skillsCharacterLimit}
          source={source}
        />
      ))}
    </ul>
  )
})
